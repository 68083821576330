import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import ContactTwo from '../views/ContactTwo.vue'
import ConstructionsAnnexes from '../views/ConstructionsAnnexes.vue'
import TransformationsRenovations from '../views/TransformationsRenovations.vue'
import AmenagementsExt from '../views/AmenagementsExt.vue'
import FinitionsInt from '../views/FinitionsInt.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/ContactTwo',
    name: 'ContactTwo',
    component: ContactTwo
  },
  {
    path: '/constructions-annexes',
    name: 'ConstructionsAnnexes',
    component: ConstructionsAnnexes
  },
  {
    path: '/transformations-renovations',
    name: 'TransformationsRenovations',
    component: TransformationsRenovations
  },
  {
    path: '/amenagements-ext',
    name: 'AmenagementsExt',
    component: AmenagementsExt
  },
  {
    path: '/finitions-int',
    name: 'FinitionsInt',
    component: FinitionsInt
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
