<template>
  <v-content>
      <v-sheet
        id="contact"
        tag="section"
        tile
      >
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-bold mb-5 text-uppercase text-center font-blue">Contactez nous</h2>
            <v-form v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nom*"
                    v-model='data.nom'
                    :rules="nomRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Email*"
                    v-model='data.email'
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <vue-tel-input-vuetify 
                    @country-changed="countrySelected"
                    label="GSM"
                    single-line
                    :rules="gsmRules"
                    placeholder=""
                    v-model=data.gsm
                    
                  >
                    <template v-slot:arrow-icon>
                      <strong>+{{countryCode}}</strong>
                    </template>
                  </vue-tel-input-vuetify>  
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Objet de votre demande*"
                    v-model='data.objet'
                    :rules="objetRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Message*"
                    v-model='data.message'
                    :rules="messageRules"
                    required
                  ></v-textarea>
                </v-col>

                <v-col
                  class="mx-auto"
                  cols="auto"
                >
                  <v-btn
                  @click="sendMessage()"
                  :disabled=!valid
                  >
                    Envoyer
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-content>
</template>

<script>

  export default {
    data: () => ({
      phone: null,
      countryCode: null,
      valid: false,
      data: {
        nom: '',
        email: '',
        gsm: '',
        objet: '',
        message: ''
      },
      nomRules: [
        v => !!v || 'Veuillez introduire votre nom'
      ],
      emailRules: [
        v => !!v || 'Veuillez introduire votre e-mail ',
        v => /.+@.+/.test(v) || 'Votre e-mail n\'est pas valide',
      ],
      gsmRules: [
        v => !!v || 'Veuillez introduire votre GSM',
        v => /^\(?([0-9]{4})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{2})[-. ]?([0-9]{2})$/.test(v) || 'Veuillez entrer un numéro de GSM valide'

      ],
      objetRules: [
        v => !!v || 'Veuillez introduire l\'objet de votre demande'
      ],
      messageRules: [
        v => !!v || 'Veuillez introduire votre message'
      ],
    }),
    methods: {
      countrySelected(val) {
        console.log(val)
        this.countryCode = val.dialCode;
      },

      sendMessage(){
        // console.log(allData);
        // let data = allData
        console.log(this.valid)
      }
    }
  }
</script>
