import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import router from './router'
import VueSplide from '@splidejs/vue-splide'
import CreaeSlides from '@splidejs/vue-splide'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'

Vue.config.productionTip = false

Vue.use( VueTelInputVuetify, {vuetify})
Vue.use( VueSplide )
Vue.use( CreaeSlides )
Vue.component( 'vue-cookie-accept-decline', VueCookieAcceptDecline )

new Vue({
  vuetify,
  icons: {
    iconfont: 'md'
  },
  router,
  render: h => h(App)
}).$mount('#app')
