<template>
<v-main>
    <v-container
    height="100"
    >
            <h2 class="display-2 font-weight-bold mb-5 text-uppercase text-center font-blue pt-16">Contactez nous</h2>
            <v-row cols=12>
                <v-col cols="12">
                    <v-text-field
                        value="+32 475 61 09 35"
                        type='tel'
                        readonly
                        flat
                        prepend-icon="call"
                    ></v-text-field>
                    <v-text-field
                        value="constructdeblander@hotmail.com"
                        type='email'
                        readonly
                        flat
                        prepend-icon="mail"
                    ></v-text-field>
                    <v-text-field
                        value="Chemin de la fosse aux chats, 20 | 5170 - PROFONDEVILLE (NAMUR)"
                        type="text"
                        readonly
                        flat
                        prepend-icon="location_on"
                    ></v-text-field>
                    <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=3.9385986328125004%2C49.986552130506176%2C5.751342773437501%2C50.79551936692376&amp;layer=mapnik&amp;marker=50.39249310002965%2C4.8450511999999435" style="border: 1px solid black; text-align: center"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=50.3925&amp;mlon=4.8451#map=10/50.3925/4.8451">View Larger Map</a></small>
                </v-col>
            </v-row>
    </v-container>
</v-main>
</template>

<style>
    .logo{
        display: flex;
        align-items: center;
    }
</style>

<script>
export default {
    data: () => ({})
}
</script>
