<template> 
  <v-main>
    <section id="hero">

      <!-- Only Desktop viewport -->
      <v-row no-gutters  class="d-md-flex d-none">
        <v-img
          height="400px"
          :src="img1"
        >
          <v-container style="width: 100%; margin-left: 3%;">
            <v-img
            align-center 
              contain
              max-width="500"
              src="@/assets/ConstructDeblanderLogoWhite.png"
            >
            </v-img>
          </v-container>
        </v-img>
      </v-row>

      <!-- Only Mobile viewport -->
      <v-row no-gutters class="d-md-none" >
        <v-img
          height="200px"
          :src="img1"
        >
          <v-container style="width: 100%; margin-left: 20%; margin-top: 5%;">
            <v-img
            align-center
              contain
              max-width="50%"
              src="@/assets/ConstructDeblanderLogoWhite.png"
            >
            </v-img>
          </v-container>
        </v-img>
      </v-row>
      <iframe  class="fb d-none d-md-flex" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDeblanderConstruct%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </section>

    <section id="about-us">
      <div class="py-5"></div>

      <v-container class="text-start">
        <h2 class="display-2 font-weight-bold mb-3 font-blue">A PROPOS DE NOUS</h2>

        <v-responsive
          class="font-weight-light mb-8 text-start"
          max-width="500"
        >
          L’entreprise Construct Deblander est active depuis 1994, en s’adaptant continuellement à l'évolution des nouvelles technologies de la construction. Elle dispose d’une expérience solide qui vous permet d’aboutir à la réalisation complète de vos projets en toute quiétude. Notre état d’esprit se traduit par une entreprise familiale et un savoir-faire maîtrisé.
        </v-responsive>
      </v-container>

      <div class="py-12 d-md-flex d-none"></div>
    </section>

    <!-- Only Desktop viewport -->
    <section id="stats" class="d-md-flex d-none mb-16">
      <v-parallax
        src="@/assets/global2.jpg"
        style="width: 100%"
      >
        <v-container fill-height>
          <v-row class="mx-auto" style="background-color:rgba(255, 255, 255, 0.5);">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="4"
            >
              <div class="text-center" style="color: black;">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <!-- Only Desktop viewport -->
    <section id="blog" class="d-md-flex d-none">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-5 text-uppercase text-center font-blue" >NOS SERVICES</h2>

        <v-row>
          <v-col
            v-for="({ id, text, title, link, image, imageDiver}, i) in features"
            :key="i"
            cols="6"
          >
            <v-card
              :img=image
              class="card-services mb-3"
              height="500"
            >
              <v-card-title
                class="font-weight-black text-center text-uppercase"
                v-text="title"
              ></v-card-title>

              <v-card-text
                min-height="350"
              >
                <div>
                  <v-img
                      max-height="100"
                      max-width="100"
                      class="mt-4"
                      :src=imageDiver></v-img>
                </div>
              </v-card-text>

              <v-card-actions
                class="font-weight-medium body-1"
                v-text="text"
                height="100%"
              ></v-card-actions>
            </v-card>

          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-btn
              color="#24206b"
              dark
              class="my-6 font-weight-black text-center text-uppercase"
              width="90%"
              @click="() => {$router.push(link)}"
              >
                Contactez-nous
              </v-btn>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <!-- Only Mobile viewport -->
    <section class="d-md-none">
      <v-container>
        <v-row>
          <v-col>
            <v-card
                class="mx-12"
                max-height="100%"
            >
              <v-card-title
                class="font-weight-black text-center text-uppercase"
              >
                Contactez-nous
              </v-card-title>

              <v-card-text
                  class="d-flex d-flex flex-column py-14 px-16"
              >
                <v-btn
                    class="my-4"
                    href="tel:+32475610935"
                >
                  <v-icon x-large class="mx-8">call</v-icon>
                </v-btn>
                <v-btn
                    class="my-4"
                    href="mailto:constructdeblander@hotmail.com"
                >
                  <v-icon x-large class="mx-8">mail</v-icon>
                </v-btn>
                <v-btn
                    class="my-4"
                    href="https://www.facebook.com/DeblanderConstruct"
                >
                  <v-icon x-large class="mx-8">facebook</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>
      </v-container>

    </section>

    <!-- <section id="partenaires">
      <div class="py-12"></div>

      <v-container fluid class="text-center">
        <h2 class="display-2 font-weight-bold mb-3 font-blue">NOS PARTENAIRES</h2>

        <v-responsive
          class="mx-auto mb-8"
          width="56"
        >
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>
        
        <vueper-slides 
          class="no-shadow" 
          arrows-outside 
          transition-speed="250"
          autoplay
          :bullets="false"
          :pauseOnHover=true
          style="width: 50%; margin-left: 25%;"
        >
            <vueper-slide image="https://maisonpassion.be/wp-content/uploads/2020/06/maison-passion-logo-fond-rouge.png" />
            <vueper-slide image="https://www.nonet-entreprise-construction.be/sites/all/themes/custom/expansion/dist/images/logo.png" />
            <vueper-slide image="https://www.hublet-ets.be/sites/all/themes/custom/expansion/dist/images/logo.png" />
        </vueper-slides>


      </v-container>

      <div class="py-12"></div>
    </section> -->

  </v-main>
</template>

<style>


  .card-services, .v-card__title{
    background-color: #24206b;
    margin: 0;
    color: white;
    display: flex;
    justify-content: center;
  }
  .card-services, .v-card__text{
    min-height: 300px;

    color: #24206b !important;
  }

  .card-services, .v-card__actions{
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0;
    color: #24206b !important;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

  .fb{
    background-color: white;
    position: absolute;
    right: 10%;
    top: 8%;
    z-index: 1000;
  }
</style>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/fr_FR/sdk.js#xfbml=1&version=v10.0" nonce="LeVg0i0x"></script>
<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'


export default {
  // components: {VueperSlides, VueperSlide},
  data () {
      return { 
        colors: {
          blue: '#24206b'
        },
        img1: require('@/assets/photoHome.jpg'),
        link: 'ContactTwo',
        features: [
          {
            id: '1',
            title: 'Constructions / Annexes',
            text: 'Nous vous accompagnons pour la réalisation de votre nouvelle construction ou extension de votre bien immobilier. Et nous sélectionnons des partenaires fiables pour tous les autres postes nécessaires à la réalisation de votre projet.',
            link:'contact',
            image: require('@/assets/chantier-maison.jpg'),
            imageDiver: ''
          },
          {
            id: '2',
            title: 'Transformations / Renovations',
            text: 'Nous sommes spécialisés dans la réalisation de transformation ou rénovation de votre bien immobilier (véranda, salle de bain, extension, … ).',
            link: 'contact',
            image: require('@/assets/transformationRenovation.jpg'),
            imageDiver: ''
          },
          {
            id: '3',
            title: 'Aménagements extérieur',
            text: 'Nous mettons nos compétences à votre service  pour la réalisons de tous types d’aménagement extérieur',
            link: 'contact',
            image: require('@/assets/amenagementExt.jpg'),
            imageDiver: require('@/assets/marlux_certified.png')
          },
          {
            id: '4',
            title: 'Finition intérieur',
            text: 'Nous pouvons également vous proposer différents services de parachèvement. (Carrelage, plafonnage, parquet, menuiserie int, sablage et rejointoyage, …)',
            link: 'contact',
            image: require('@/assets/finitionInt.jpg'),
            imageDiver: ''
          },
        ],
        stats: [
          ['Depuis 1994', 'Expérience'],
          ['Devis', 'Gratuit'],
          ['Qualite', 'Patron sur chantier']
        ]
      }
    },
    methods: {
      openCard(id){
        this.cards.forEach(element => {
          if (element == id) {
            this.cards.element = true
          }
        });
      }
    }
}
</script>
