<template>
  <v-app>    
    <v-toolbar
      height="30 !important"
      color="#24206b"
    >
      <v-spacer></v-spacer>

      <v-toolbar-items class="d-md-flex d-none">
        <v-btn plain small to="/" color="white">Accueil</v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="d-md-flex d-none">
        <v-btn plain small to="ContactTwo" color="white">Contact</v-btn>
      </v-toolbar-items>
      <v-toolbar-items class="d-md-flex d-none ">
        <v-btn plain small>
          <v-icon color="white" href="https://www.facebook.com/DeblanderConstruct">facebook</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
      <router-view/>

    <vue-cookie-accept-decline
        :ref="'myPanel1'"
        :elementId="'myPanel1'"
        :debug="false"
        :position="'bottom-left'"
        :type="'floating'"
        :disableDecline="false"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false">

      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        En naviguant sur ce site, vous acceptez l'enregistrement de Cookie dans votre navigateur.
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        Non merci
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        Je suis d'accord
      </div>
    </vue-cookie-accept-decline>
      
    <v-footer
      class="justify-center"
      height="100"
    >
      <div class=" font-weight-light text-center">
        DEBLANDER CONSTURCT | Droits d'auteur &copy;{{ (new Date()).getFullYear() }} Tous droits réservés
      </div>
    </v-footer>

  </v-app>
</template>

<script>
  import VueCookieAcceptDecline from "vue-cookie-accept-decline";

  export default {
    components: {
      VueCookieAcceptDecline
    },
    data() {
      return {
      }
    },
    methods: {
      goToHome() {
        this.$router.push("/")
      },
      cookieStatus (){},
      cookieClickedAccept (){},
      cookieClickedDecline (){},
    }
  }
</script>

<style>
  .font-blue {
    color: #24206b;
  }
  header{
    max-height: 30px !important;
  }
</style>